import React, { useState } from 'react';
import "./App.css";
import Editor from "./Editor";
import preeti from "./function/preeti";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css'; // Import ReactQuill styles

function App() {
  const [value, setValue] = useState("");
  const [NormalText, setNormalText] = useState("");

  // Function to copy text to clipboard
  // const copyToClipboard = () => {
  //   const htmlToCopy = preeti(value); // Assuming preeti(value) returns HTML
  //   const el = document.createElement('div'); // Create a textarea element
  //   el.innerHTML = htmlToCopy; // Set its HTML content to the HTML to copy
  //   const textToCopy = el.textContent || el.innerText || ""; // Extract text content
    
  //   navigator.clipboard.writeText(textToCopy) // Copy the extracted text
  //     .then(() => alert('Text copied to clipboard!'))
  //     .catch((err) => alert('Failed to copy text: ', err));
  // }

  return (
    <div className="app-container">
      <div className="main-container">
        <div className="text-container">
          <h2>Your Text to Convert (Preeti):</h2>
          <Editor
            value={value}
            NormalText={NormalText}
            setNormalText={setNormalText}
            setValue={setValue}
          />
        </div>
        <div className="text-container">
          <h2>Converted Text (Unicode):</h2>
          <ReactQuill
            theme="snow"
            value={preeti(value)}
            readOnly={true}
            placeholder={"Your converted text will be available here..."}
          />
          {/* <button className="copy-button" onClick={copyToClipboard}>Copy</button> */}
        </div>
      </div>
      <footer>
        Made by <a target="_blank" rel="noreferrer" href="https://smilepant.com/">Smile Pant</a> Ⓒ All rights reserved
      </footer>
    </div>
  );
}

export default App;
