import React from "react";
import ReactQuill from "react-quill";
import EditorToolbar, { modules, formats } from "./EditorToolbar";
import "react-quill/dist/quill.snow.css";

export const Editor = ({ value, setValue, setNormalText }) => {
  const handleChange = (content, delta, source, editor) => {
    setValue(editor.getHTML());
    setNormalText(editor.getText());
  };
  return (
    <div className="text-editor">
      <EditorToolbar />
      <ReactQuill
        theme="snow"
        value={value}
        onChange={handleChange}
        placeholder={"Write something to convert.................................Made by Smile Pant"}
        modules={modules}
        formats={formats}
      />
    </div>
  );
};

export default Editor;
