var all_rules = {
  preeti: {
    name: "Preeti",
    "post-rules": [
      ["्ा", ""],
      ["(त्र|त्त)([^उभप]+?)m", "$1m$2"],
      ["त्रm", "क्र"],
      ["त्तm", "क्त"],
      ["([^उभप]+?)m", "m$1"],
      ["उm", "ऊ"],
      ["भm", "झ"],
      ["पm", "फ"],
      ["इ{", "ई"],
      // ["ि((.्)*[^्])", "$1ि"],
      ["(.[ािीुूृेैोौंःँ]*?){", "{$1"],
      ["((.्)*){", "{$1"],
      ["{", "र्"],
      ["([ाीुूृेैोौंःँ]+?)(्(.्)*[^्])", "$2$1"],
      ["्([ाीुूृेैोौंःँ]+?)((.्)*[^्])", "्$2$1"],
      ["([ंँ])([ािीुूृेैोौः]*)", "$2$1"],
      ["ँँ", "ँ"],
      ["ंं", "ं"],
      ["ेे", "े"],
      ["ैै", "ै"],
      ["ुु", "ु"],
      ["ूू", "ू"],
      ["^ः", ":"],
      ["टृ", "ट्ट"],
      ["ेा", "ाे"],
      ["ैा", "ाै"],
      ["अाे", "ओ"],
      ["अाै", "औ"],
      ["अा", "आ"],
      ["एे", "ऐ"],
      ["ाे", "ो"],
      ["ाै", "ौ"],
    ],
    v: "1.0.1",
    "char-map": {
      "÷": "/",
      v: "ख",
      r: "च",
      '"': "ू",
      "~": "ञ्",
      z: "श",
      ç: "ॐ",
      f: "ा",
      b: "द",
      n: "ल",
      j: "व",
      "×": "×",
      V: "ख्",
      R: "च्",
      ß: "द्म",
      "^": "६",
      Û: "!",
      Z: "श्",
      F: "ँ",
      B: "द्य",
      N: "ल्",
      Ë: "ङ्ग",
      J: "व्",
      6: "ट",
      2: "द्द",
      "¿": "रू",
      ">": "श्र",
      ":": "स्",
      "§": "ट्ट",
      "&": "७",
      "£": "घ्",
      "•": "ड्ड",
      ".": "।",
      "«": "्र",
      "*": "८",
      "„": "ध्र",
      w: "ध",
      s: "क",
      m: "फ",
      g: "न",
      æ: "“",
      c: "अ",
      o: "य",
      k: "प",
      W: "ध्",
      Ö: "=",
      S: "क्",
      Ò: "¨",
      _: ")",
      "[": "ृ",
      Ú: "’",
      G: "न्",
      ˆ: "फ्",
      C: "ऋ",
      O: "इ",
      Î: "ङ्ख",
      K: "प्",
      7: "ठ",
      "¶": "ठ्ठ",
      3: "घ",
      9: "ढ",
      "?": "रु",
      ";": "स",
      "'": "ु",
      "#": "३",
      "¢": "द्घ",
      "/": "र",
      "+": "ं",
      ª: "ङ",
      t: "त",
      p: "उ",
      "|": "्र",
      x: "ह",
      å: "द्व",
      d: "म",
      "`": "ञ",
      l: "ि",
      h: "ज",
      T: "त्",
      P: "ए",
      Ý: "ट्ठ",
      "\\": "्",
      Ù: ";",
      X: "ह्",
      Å: "हृ",
      D: "म्",
      "@": "२",
      Í: "ङ्क",
      L: "ी",
      H: "ज्",
      4: "द्ध",
      "±": "+",
      0: "ण्",
      "<": "?",
      8: "ड",
      "¥": "र्‍",
      $: "४",
      "¡": "ज्ञ्",
      ",": ",",
      "©": "र",
      "(": "९",
      "‘": "ॅ",
      u: "ग",
      q: "त्र",
      "}": "ै",
      y: "थ",
      e: "भ",
      a: "ब",
      i: "ष्",
      "‰": "झ्",
      U: "ग्",
      Q: "त्त",
      "]": "े",
      "˜": "ऽ",
      Y: "थ्",
      Ø: "्य",
      E: "भ्",
      A: "ब्",
      M: "ः",
      Ì: "न्न",
      I: "क्ष्",
      5: "छ",
      "´": "झ",
      1: "ज्ञ",
      "°": "ङ्ढ",
      "=": ".",
      Æ: "”",
      "‹": "ङ्घ",
      "%": "५",
      "¤": "झ्",
      "!": "१",
      "-": "(",
      "›": "द्र",
      ")": "०",
      "…": "‘",
      Ü: "%",
    },
  },
  htmlRule: [
    ["\\?उश्र(.*?)\\?रउश्र", "<p>$1</p>"],
    ["\\?जज्ञश्र(.*?)\\?रजज्ञश्र", "<h1>$1</h1>"],
    ["\\?जद्दश्र(.*?)\\?रजद्दश्र", "<h2>$1</h2>"],
    ["\\?दचश्र", "<br>"],
    ["\\?कतचयलनश्र(.*?)\\?रकतचयलनश्र", "<strong>$1</strong>"],
    ["\\?झश्र(.*?)\\?रझश्र", "<em>$1</em>"],
    ["\\?कश्र(.*?)\\?रकश्र", "<s>$1</s>"],
    ["\\?यश्रि(.*?)\\?रयश्रि", "<ol>$1</ol>"],
    ["\\?गश्रि(.*?)\\?रगश्रि", "<ul>$1</ul>"],
    ["\\?गश्र(.*?)\\?रगश्र", "<u>$1</u>"],
    ["\\?ष्श्रि(.*?)\\?रष्श्रि", "<li>$1</li>"],
    [
      "\\?दयिअपत्रगयतभश्र(.*?)\\?रदयिअपत्रगयतभश्र",
      "<blockquote>$1</blockquote>",
    ],
    ["\\?कगउश्र(.*?)\\?रकगउश्र", "<sup>$1</sup>"],
    ["\\?कगदश्र(.*?)\\?रकगदश्र", "<sub>$1</sub>"],
  ],
};

function preeti(text, font) {
  font = "Preeti";
  font = font.toLowerCase();
  var myFont = all_rules[font];

  // Function to convert characters using the character map.
  function convertCharacters(input) {
    var output = "";
    for (var w = 0; w < input.length; w++) {
      var letter = input[w];
      output += myFont["char-map"][letter] || letter;
    }
    return output;
  }

  // Function to apply post-rules.
  function applyPostRules(input) {
    for (var r = 0; r < myFont["post-rules"].length; r++) {
      input = input.replace(
        new RegExp(myFont["post-rules"][r][0], "g"),
        myFont["post-rules"][r][1]
      );
    }
    return input;
  }

  // First, convert characters that are not part of any HTML tag.
  let convertedText = text.replace(/([^<>]+)(?=<|$)/g, function(match) {
    let converted = convertCharacters(match);
    converted = applyPostRules(converted);
    return converted;
  });

  // Apply HTML rules.
  for (var p = 0; p < all_rules.htmlRule.length; p++) {
    convertedText = convertedText.replace(
      new RegExp(all_rules.htmlRule[p][0], "g"),
      all_rules.htmlRule[p][1]
    );
  }

  return convertedText;
}

export default preeti;
